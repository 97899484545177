import "./styles.scss"

import React from "react"

const Radio = ({
  children,
  name,
  checked,
  onChange,
  required,
  className,
  error,
  disabled,
  value,
}) => {
  return (
    <div
      className={`radio${className ? ` ${className}` : ""}${
        error ? ` radio__error` : ""
      }${disabled ? ` radio__disabled` : ""}`}
    >
      <label className="radio__label">
        <input
          type="radio"
          name={name}
          checked={checked}
          // defaultChecked={checked}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
        />
        <span>{children}</span>
      </label>
    </div>
  )
}

export default Radio
